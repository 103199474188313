import { Box, Button, Dialog, DialogContent, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import PremiumPurchaseButton from '../Purchases/premiumPurchaseButton';
import Lottie from 'lottie-react';
import LoadingFace from '../../assets/animations/LoadingFace.json'
import { getPrices, setCustomPrice } from '../../utils/api';
import { wrapPromise } from '../../utils/helper';
import { Timestamp } from 'firebase/firestore';
import TiersCard from '../Purchases/TiersCard';

interface EditCustomPriceCardProps {
  onSaveFunction?: () => void;
}

const EditCustomPriceCard: React.FC<EditCustomPriceCardProps> = ({onSaveFunction}) => {
    const { authUser } = useAuth();
    const [ requiredPremiumKey, setRequiredPremiumKey ] = useState<string>("");
    const [ description, setDescription ] = useState<string>(`Unlock access to ${authUser.fullName}'s AI persona`);
    const [ price, setPrice ] = useState<string>("10.00")
    const [ frequency, setFrequency ] = useState<string>("month")
    const [ sampleData, setSampleData ] = useState<any>({
      "currency": 'usd',
      "description": description,
      "recurring": {"interval": frequency},
      "title": `Subscribe to ${authUser.fullName}`,
      "type": 'recurring',
      "unit_amount": parseFloat(price)*100,
    });
    const [ existingData, setExistingData ] = useState({});
    const [ isSaving, setIsSaving ] = useState<boolean>(false);
    const [ hasSaved, setHasSaved ] = useState<boolean>(false);
    const [ showTiersCard, setShowTiersCard ] = useState<boolean>(false);
    const loadedData = useRef(wrapPromise(Promise.resolve()));
    const debounceTime = 250;

    useEffect(() => {
      if (authUser.subscription?.custom_product_id) {
        let key = authUser.subscription?.custom_product_id;
        // await lookup of existing price
        loadedData.current = wrapPromise(
          getPrices([key])
          .then((res) => {
            if (res.ok) {
              return res.json()
            } else {
              throw res;
            }
          }).then((data) => {
            // set description, price, frequency and requiredPremiumKey
            setDescription(data[key].description)
            setPrice((data[key].unit_amount/100).toString())
            setFrequency(data[key].recurring['interval'])
            let formattedData = {
              "currency": 'usd',
              "description": data[key].description,
              "last_updated": "on " + (new Date(data[key].last_updated * 1000)).toLocaleString(),
              "recurring": {"interval": data[key].recurring['interval']},
              "title": `Subscribe to ${authUser.fullName}`,
              "type": 'recurring',
              "unit_amount": data[key].unit_amount,
            }
            setSampleData(formattedData)
            setExistingData({...formattedData})
            setRequiredPremiumKey(key)
          }).catch((error) => {
            // To-do: Error handling
            console.log(error)
          })
        )
      }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (shouldUpdateSampleData()) {
        let temp = setTimeout(() => {
          let newSampleData = {...sampleData};
          newSampleData.description = description;
          newSampleData.recurring = {...newSampleData.recurring};
          newSampleData.recurring['interval'] = frequency;
          newSampleData.unit_amount = parseFloat(price)*100;
          setSampleData(newSampleData)
        }, debounceTime);

        return () => clearTimeout(temp);
      }
    }, [description, price, frequency]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (hasSaved) {
        setTimeout(() => {
          setHasSaved(false)
          if (onSaveFunction) {
            onSaveFunction()
          }
        }, 2000)
      }
    }, [hasSaved]) //eslint-disable-line react-hooks/exhaustive-deps

    const canEditPriceCard: (() => boolean) = () => {
      // // check if authUser.subscription.pricing_plan.tier and .expiration_date are valid
      // let current_plan = authUser?.subscription?.pricing_plan;
      // if (current_plan?.tier >= 2000) {
      //   let currentTimeStamp = Timestamp.fromDate(new Date());
      //   if (current_plan.expiration_date > currentTimeStamp) {
      //     return true
      //   }
      // }

      // product design change, everyone can create and edit their custom price card
      return true
    }

    const handleSaveChanges = () => {
      if (!canEditPriceCard()) {
        setShowTiersCard(true)
        return
      }

      if (!isSaving && !hasSaved) {
        setIsSaving(true)

        let mode: ('create' | 'update') = (!!requiredPremiumKey ? 'update' : 'create')

        setCustomPrice(
          authUser.documentId,
          mode,
          String(sampleData.title),
          description,
          parseFloat(price)*100,
          frequency,
          requiredPremiumKey
        ).then((res: any) => {
          if (res.ok) {
            return res.json()
          } else {
            throw res
          }
        }).then((data: any) => {
          if (data['data_modified']){
            setHasSaved(true);

            setExistingData({...sampleData, "last_updated": "just now"})
            if (!requiredPremiumKey) {
              setRequiredPremiumKey(data.product)
            }
          } else {
            throw new Error('Failed to save changes...')
          }
        }).catch((error) => {
          // To-do: Error handling
          console.log(error)
        }).finally(() => {
          setIsSaving(false);
        })
      }
    }

    const LoadedPurchaseButton = () => {
      loadedData.current.read();

      return (
        <PremiumPurchaseButton
          requiredPremiumKey={requiredPremiumKey}
          sampleData={sampleData}
        />
      )
    }

    const shouldUpdateSampleData: (() => boolean) = () => {
      if (description !== sampleData.description) return true;
      if (frequency !== sampleData.recurring['interval']) return true;
      if (Number(price) !== (Number(sampleData['unit_amount'])/100)) return true;
      return false;
    }

    const hasChangedData: (() => boolean) = () => {
      if (description !== existingData['description']) return true;
      if (Number(price) !== existingData['unit_amount']/100) return true;
      if (frequency !== existingData['recurring']['interval']) return true;
      return false;
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        <Typography
          variant='subtitle2'
          fontSize='1.1rem'
          color='#000'
          fontWeight={600}
          pb={0.5}
        >
          Edit Price Card
        </Typography>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          width='100%'
        >
          <Typography
            variant='subtitle2'
            color='#000'
            fontWeight={400}
            pb={1}
          >
            Price Card Preview
          </Typography>

          <Suspense fallback={
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{
                backgroundColor: 'gray',
                border: '1px solid lightgray',
                borderRadius: '5px',
              }}
              width='100%'
              height='145px'
            >
              <Lottie
                animationData={LoadingFace}
                loop={true}
                style={{
                  width: '100px',
                  height: '100px',
                }}
              />
            </Box>
          }>
            <LoadedPurchaseButton />
          </Suspense>

          <Typography
            variant='subtitle2'
            color='#000'
            fontWeight={400}
            pt={4}
            pb={1}
          >
            Price Card Title
          </Typography>

          <TextField
            disabled
            id='title'
            value={`Subscribe to ${authUser.fullName}`}
            variant='outlined'
            size='small'
            sx={{
              "& input.Mui-disabled": {
                backgroundColor: 'lightgray',
                borderRadius: '10px',
                color: '#3f3f3f',
                "-webkit-text-fill-color": '#3f3f3f'
              },
              ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: '#312D2D',
              }
            }}
          />

          <Typography
            variant='subtitle2'
            color='#000'
            fontWeight={400}
            pt={2}
            pb={1}
          >
            Description / Benefits
          </Typography>

          <TextField
            id='description'
            value={description}
            variant='outlined'
            size='small'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
          />

          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant='subtitle2'
                color='#000'
                fontWeight={400}
                pt={2}
                pb={1}
              >
                Subscription Price
              </Typography>

              <TextField
                id='price'
                value={price}
                variant='outlined'
                size='small'
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant='subtitle2'
                color='#000'
                fontWeight={400}
                pt={{xs: 0, sm: 2}}
                pb={1}
              >
                Subscription Frequency
              </Typography>

              <Select
                id='frequency'
                fullWidth
                value={frequency}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFrequency(e.target.value)}
              >
                <MenuItem value={'day'}>Per Day</MenuItem>
                <MenuItem value={'month'}>Per Month</MenuItem>
                <MenuItem value={'year'}>Per Year</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            pt={3}
          >
            {
              existingData["last_updated"] &&
              <Typography
                variant='subtitle2'
                fontSize='0.8rem'
                sx={{
                  fontStyle: 'italic',
                  color: 'gray',
                }}
              >
                Price Card last updated {existingData["last_updated"]}
              </Typography>
            }
            <Button
              sx={{
                ml: 2,
                borderRadius: 20,
                pl: 4,
                pr: 4,
              }}
              variant='contained'
              color={hasSaved ? 'success' : 'mainBlue'}
              disabled={isSaving || (!hasChangedData() && !hasSaved)}
              onClick={handleSaveChanges}
            >
              {
                hasSaved ? (
                  "Saved!"
                ) :
                isSaving ?
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Lottie
                    animationData={LoadingFace}
                    loop={true}
                    style={{
                      maxWidth: '30px',
                      maxHeight: '30px',
                      marginRight: '8px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  Saving...
                </Box>
                : "Save"
              }
            </Button>
          </Box>
        </Box>

        <Dialog
          open={showTiersCard}
          onClose={() => setShowTiersCard(false)}
          fullWidth
          maxWidth='lg'
        >
          <DialogContent>
            <TiersCard cardTitle='Upgrade to Premium To Access This Feature (And Many Others!)'/>
          </DialogContent>
        </Dialog>
      </Box>
    )
}

export default EditCustomPriceCard
